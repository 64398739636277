import React from "react";
import { motion } from "framer-motion";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";

interface CenterHeroProps {
  announcement?: {
    text: string;
    link: string;
  };
  title: string;
  subtitle: string;
  primaryCTA: {
    text: string;
    onClick: () => void;
  };
  secondaryCTA?: {
    text: string;
    link: string;
  };
}

const CenterHero: React.FC<CenterHeroProps> = ({
  announcement,
  title,
  subtitle,
  primaryCTA,
  secondaryCTA,
}) => {
  return (
    <motion.div
      id="center-hero"
      className="min-h-[600px] flex flex-col items-center justify-center text-center px-4 py-16"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {announcement && (
        <motion.div
          className="mb-8"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Badge
            variant="secondary"
            className="py-2 px-4"
          >
            {announcement.text}
          </Badge>
        </motion.div>
      )}
      <motion.h1
        className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 max-w-4xl text-foreground"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        {title}
      </motion.h1>
      <motion.p
        className="text-lg md:text-xl text-muted-foreground mb-8 max-w-2xl"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        {subtitle}
      </motion.p>
      <motion.div
        className="flex flex-col sm:flex-row items-center gap-4"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <Button
          onClick={primaryCTA.onClick}
          size="lg"
          className="bg-blue-600 hover:bg-blue-700 text-white dark:bg-blue-500 dark:hover:bg-blue-600 shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1"
        >
          {primaryCTA.text}
        </Button>
        {secondaryCTA && (
          <Button
            variant="outline"
            size="lg"
            asChild
            className="border-blue-200 dark:border-blue-800 hover:border-blue-300 dark:hover:border-blue-700"
          >
            <a
              href={secondaryCTA.link}
              className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
            >
              {secondaryCTA.text} →
            </a>
          </Button>
        )}
      </motion.div>
    </motion.div>
  );
};

export default CenterHero;
