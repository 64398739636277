import React from "react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { useAuth } from "../../hooks/useAuth";
import { supabase } from "../../services/supabase";
import { Button } from "../ui/button";
import { Loader2 } from "lucide-react";

interface AuthButtonProps {
  className?: string;
}

const AuthButton: React.FC<AuthButtonProps> = ({ className = "" }) => {
  const { user, loading, signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      let origin = window.location.origin;
      origin = origin.replace("#", "");

      console.log("origin", origin);
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${origin}/auth/callback/`,
        },
      });
      if (error) throw error;
      amplitude.logEvent("USER_SIGN_IN_ATTEMPT");
    } catch (error) {
      console.error("Error signing in with Google:", error);
      // TODO: Implement a proper error handling mechanism
      alert("Error signing in with Google. Please try again.");
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      amplitude.logEvent("USER_SIGNED_OUT");
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      // TODO: Implement a proper error handling mechanism
      alert("Error signing out. Please try again.");
    }
  };

  if (loading) {
    return (
      <Button id="auth-button-loading" className={className} disabled>
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        Loading
      </Button>
    );
  }

  if (user) {
    return (
      <Button
        id="auth-button-signout"
        onClick={handleSignOut}
        variant="destructive"
        className={className}
      >
        <span className="">Sign Out</span>
      </Button>
    );
  }

  return (
    <Button
      id="auth-button-signin"
      onClick={handleSignIn}
      variant="default"
      className={className}
    >
      <span className="hidden sm:inline">Sign In With Google</span>
      <span className="sm:inline md:hidden">Sign In</span>
    </Button>
  );
};

export default AuthButton;