import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../services/supabase";
import { logEvent } from "../services/analytics";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "../components/ui/accordion";
import { Button } from "../components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../components/ui/card";
import { Course, CourseSection } from "../types/supabase";
import { downloadCourse } from "../utils/downloadCourse";
import ReactMarkdown from "react-markdown";

const CourseViewPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [course, setCourse] = useState<Course | null>(null);
  const [sections, setSections] = useState<CourseSection[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCourse = async () => {
      if (id) {
        try {
          const { data: courseData, error: courseError } = await supabase
            .from("courses")
            .select("*")
            .eq("id", id)
            .single();

          if (courseError) throw courseError;

          const { data: sectionsData, error: sectionsError } = await supabase
            .from("course_sections")
            .select("*")
            .eq("course_id", id)
            .order("id");

          if (sectionsError) throw sectionsError;

          setCourse(courseData);
          setSections(sectionsData);
          logEvent("COURSE_VIEWED", { courseId: id, topic: courseData.topic });
        } catch (error) {
          console.error("Error fetching course:", error);
          logEvent("COURSE_VIEW_ERROR", {
            courseId: id,
            error: (error as Error).message,
          });
          // TODO: Add error handling (e.g., display error message to user)
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCourse();
  }, [id]);

  const handleDownload = () => {
    if (course && sections.length > 0) {
      downloadCourse(course, sections);
      logEvent("COURSE_DOWNLOADED", {
        courseId: course.id,
        topic: course.topic,
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!course) {
    return <div>Course not found</div>;
  }

  return (
    <div id="course-view-container" className="max-w-4xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">{course.topic}</h1>
        <Button onClick={handleDownload} id="download-course-button">
          Download Course
        </Button>
      </div>
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Course Details</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            <strong>Preferences:</strong> {course.preferences}
          </p>
          <p>
            <strong>Created:</strong>{" "}
            {new Date(course.created_at!).toLocaleDateString()}
          </p>
        </CardContent>
      </Card>
      <Accordion type="single" collapsible className="w-full">
        {sections.map((section, index) => (
          <AccordionItem key={section.id} value={`section-${section.id}`}>
            <AccordionTrigger>
              Section {index + 1}: {section.title}
            </AccordionTrigger>
            <AccordionContent>
              <div className="markdown-content">
                <ReactMarkdown>
                  {section.content}
                </ReactMarkdown>
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default CourseViewPage;
