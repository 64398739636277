import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { useAuth } from '../hooks/useAuth';
import { logEvent } from '../services/analytics';

const PricingPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handlePurchase = async () => {
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId: 'price_course_generation', // You'll create this price in Stripe
          userId: user.id,
        }),
      });

      const session = await response.json();

      if (session.url) {
        logEvent('COURSE_PURCHASE_STARTED');
        window.location.href = session.url;
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      // TODO: Handle error (e.g., show error message to user)
    }
  };

  return (
    <div id="pricing-page" className="max-w-2xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Generate Your Custom Course</h1>
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="text-2xl text-center">AI-Powered Course Generation</CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          <p className="text-4xl font-bold mb-4">$19.99</p>
          <ul className="mt-4 space-y-2 text-left max-w-md mx-auto">
            <li>✓ Generate one custom course</li>
            <li>✓ AI-tailored content</li>
            <li>✓ Lifetime access to your generated course</li>
            <li>✓ Downloadable course materials</li>
            <li>✓ 30-day money-back guarantee</li>
          </ul>
        </CardContent>
        <CardFooter>
          <Button onClick={handlePurchase} className="w-full text-lg py-6">
            Generate Your Course Now
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default PricingPage;