import { Course, CourseSection } from '../types/supabase';

export const downloadCourse = (course: Course, sections: CourseSection[]) => {
  const content = `
Course: ${course.topic}

Preferences: ${course.preferences}

${sections.map((section, index) => `
Section ${index + 1}: ${section.title}
${section.content}
`).join('\n')}

Generated on: ${new Date(course.created_at!).toLocaleString()}
Last updated: ${new Date(course.updated_at!).toLocaleString()}
  `.trim();

  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${course.topic.replace(/\s+/g, '_')}_course.txt`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};