import React from "react";
import { useNavigate } from "react-router-dom";
import CenterHero from "../components/sections/CenterHero";
import SideHero from "../components/sections/SideHero";
import FeaturesSection from "../components/sections/FeaturesSection";
import { logEvent } from "../services/analytics";
import { useAuth } from "../hooks/useAuth";
import { toast } from "react-toastify";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleGetStarted = () => {
    logEvent("GET_STARTED_CLICKED");
    if (!user) {
      toast.error("You must be logged in to generate a crash course.");
      return;
    }
    navigate("/generate-course");
  };

  return (
    <div id="home-page">
      <CenterHero
        announcement={{
          text: "New: AI-powered course outlines!",
          link: "/blog/ai-course-outlines",
        }}
        title="Create Your Crash Course in Minutes"
        subtitle="Generate custom, AI-powered crash courses on any topic, tailored to your learning style."
        primaryCTA={{
          text: "Start Learning 📚",
          onClick: handleGetStarted,
        }}
        /* secondaryCTA={{
          text: "Learn More",
          link: "#features",
        }} */
      />

      <SideHero
        title="Customize Your Learning Experience"
        subtitle="Tailor your crash courses with AI. Specify your preferred tone, format, and special requests to create a personalized learning journey that fits your needs."
        imageSrc={require("../assets/library.png")}
        primaryCTA={{
          text: "Try It Now",
          onClick: handleGetStarted,
        }}
        badge={{
          text: "Powered by GPT-4",
          link: "https://openai.com/gpt-4",
        }}
      />

      <FeaturesSection
        label="Why Choose Crashify AI"
        title="Features That Make Learning a Breeze"
        subtitle="Discover how Crashify AI revolutionizes your learning experience with cutting-edge technology and user-centric design."
      />

      <SideHero
        title="From Novice to Pro in Record Time"
        subtitle="Whether you're preparing for an exam, learning a new skill for work, or exploring a hobby, Crashify AI helps you become proficient faster than ever before."
        imageSrc={require("../assets/study.png")}
        // imageAlt="Skill progression chart"
        primaryCTA={{
          text: "Start Learning",
          onClick: handleGetStarted,
        }}
        imagePosition="left"
      />
    </div>
  );
};

export default HomePage;
