import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";
import CourseGenerationPage from "./pages/CourseGenerationPage";
import CourseViewPage from "./pages/CourseViewPage";
import { ThemeProvider } from "./components/ThemeProvider";
import * as amplitude from "@amplitude/analytics-browser";
import { supabase } from "./services/supabase";
import { Session } from "@supabase/supabase-js";
import { AuthProvider } from "./hooks/useAuth";
import ProtectedRoute from "./components/common/ProtectedRoute";
import LoginPage from "./pages/LoginPage";
import AuthCallback from "./components/common/AuthCallback";
import PricingPage from "./pages/PricingPage";
import CourseOutlinePage from "./pages/CourseOutlinePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import ProcessingPage from "./pages/ProcessingPage";
import "./styles/markdown.css";

const queryClient = new QueryClient();

const App: React.FC = () => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY!, {
      autocapture: true,
    });
  }, []);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
          <Router>
            <Layout>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/auth/callback" element={<AuthCallback />} />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <DashboardPage />{" "}
                    </ProtectedRoute>
                  }
                />
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/generate-course"
                  element={<CourseGenerationPage />}
                />
                <Route path="/course/:id" element={<CourseViewPage />} />
                <Route
                  path="/course/:id/processing"
                  element={<ProcessingPage />}
                />

                <Route
                  path="/course/:id/outline"
                  element={
                    <ProtectedRoute>
                      <CourseOutlinePage />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Layout>
          </Router>
        </ThemeProvider>
        <ToastContainer />
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
