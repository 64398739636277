import { createClient } from "@supabase/supabase-js";
import { Course, CourseSection } from "../types/supabase";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error("Missing Supabase environment variables");
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const saveCourse = async (course: Course) => {
  const { data, error } = await supabase
    .from("courses")
    .insert(course)
    .select()
    .single();

  if (error) {
    throw error;
  }

  return data;
};

export const getCourses = async (userId: string) => {
  const { data, error } = await supabase
    .from("courses")
    .select("*")
    .eq("user_id", userId)
    .eq("status", "completed");

  if (error) {
    throw error;
  }

  return data;
};

export const getCourse = async (
  courseId: string
): Promise<Course & { sections: CourseSection[] }> => {
  const { data, error } = await supabase
    .from("courses")
    .select(
      `
      *,
      sections:course_sections(*)
    `
    )
    .eq("id", courseId)
    .single();

  if (error) {
    throw error;
  }

  if (!data) {
    throw new Error("Course not found");
  }

  return data as Course & { sections: CourseSection[] };
};

// TODO: Implement user authentication functions
