import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../services/supabase";
import { useAuth } from "../hooks/useAuth";
import { logEvent } from "../services/analytics";
import { Button } from "../components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../components/ui/card";

interface CourseOutline {
  topic: string;
  preferences: string;
  outline: {
    sections: {
      title: string;
      suboutline: string[];
    }[];
  };
}

const CourseOutlinePage: React.FC = () => {
  const [outline, setOutline] = useState<CourseOutline>();
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourseOutline = async () => {
      if (id) {
        const { data, error } = await supabase
          .from("courses")
          .select("*")
          .eq("id", id)
          .single();

        if (error) {
          console.error("Error fetching course:", error);
          // TODO: Handle error (e.g., show error message, redirect)
        } else if (data) {
          setOutline(data);
        }
      }
    };

    fetchCourseOutline();
  }, [id]);

  const handleGenerateCourse = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        "create-checkout-session",
        {
          body: JSON.stringify({
            courseId: id,
            priceId: "price_1Q4ML9FcXDHaFh6ppzA4apfA",
          }),
        }
      );

      if (error) throw error;

      if (data.url) {
        logEvent("COURSE_PURCHASE_STARTED", { courseId: id });
        window.location.href = data.url;
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      // TODO: Handle error (e.g., show error message to user)
    }
  };

  if (!outline) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardHeader>
        <CardTitle>{outline.topic}</CardTitle>
      </CardHeader>
      <CardContent>
        <h2 className="text-xl font-semibold mb-4">Course Outline</h2>
        <ol className="list-decimal list-inside space-y-4">
          {outline.outline.sections.map((section, index) => (
            <li key={index}>
              <h3 className="font-semibold inline">{section.title}</h3>
              <ol className="mt-1 ml-6 list-decimal">
                {section.suboutline.map((subsection, subIndex) => (
                  <li key={subIndex}>{subsection}</li>
                ))}
              </ol>
            </li>
          ))}
        </ol>
      </CardContent>
      <CardFooter>
        <Button onClick={handleGenerateCourse} className="w-full">
          Generate Full Course
        </Button>
      </CardFooter>
    </Card>
  );
};

export default CourseOutlinePage;
