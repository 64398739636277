import React from "react";
import { motion } from "framer-motion";
import {
  Zap,
  Sparkles,
  UserCircle,
  Infinity,
  Clock,
  Download,
} from "lucide-react";
import { Card, CardHeader, CardContent } from "../ui/card";

interface FeatureItem {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface FeaturesSectionProps {
  label: string;
  title: string;
  subtitle: string;
}

const FeatureIcon: React.FC<{ icon: React.ReactNode }> = ({ icon }) => (
  <div className="bg-blue-600 dark:bg-blue-500 rounded-lg p-2 w-12 h-12 flex items-center justify-center shadow-lg">
    {icon}
  </div>
);

const FeatureCard: React.FC<FeatureItem> = ({ icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
  >
    <Card className="h-full">
      <CardHeader>
        <div className="flex items-center gap-4">
          <FeatureIcon icon={icon} />
          <h3 className="text-lg font-semibold">{title}</h3>
        </div>
      </CardHeader>
      <CardContent>
        <p className="text-muted-foreground">{description}</p>
      </CardContent>
    </Card>
  </motion.div>
);

const FeaturesSection: React.FC<FeaturesSectionProps> = ({
  label,
  title,
  subtitle,
}) => {
  const features: FeatureItem[] = [
    {
      icon: <Zap className="w-6 h-6 text-white" />,
      title: "Fast Generation",
      description:
        "Create comprehensive crash courses in minutes, not hours. Our AI rapidly synthesizes information to produce ready-to-learn content on any topic.",
    },
    {
      icon: <Sparkles className="w-6 h-6 text-white" />,
      title: "Up-to-Date Content",
      description:
        "Stay ahead with the latest information. Our AI continuously updates course material, ensuring you're learning the most current and relevant content.",
    },
    {
      icon: <UserCircle className="w-6 h-6 text-white" />,
      title: "Customizable Course Content",
      description:
        "Tailor your learning experience. Specify your preferred tone, format, and special requests to create a course that matches your unique needs and interests.",
    },
    {
      icon: <Infinity className="w-6 h-6 text-white" />,
      title: "Lifetime Access",
      description:
        "Your knowledge, always accessible. Once generated, your courses are yours forever. Revisit and refresh your learning whenever you need.",
    },
    {
      icon: <Clock className="w-6 h-6 text-white" />,
      title: "Time-Efficient Learning",
      description:
        "Master subjects quickly. Our AI-crafted crash courses distill complex topics into concise, easy-to-understand modules, optimizing your study time.",
    },
    {
      icon: <Download className="w-6 h-6 text-white" />,
      title: "Downloadable Courses",
      description:
        "Learn anytime, anywhere. Download your generated courses for offline access, allowing you to study on-the-go without an internet connection.",
    },
  ];
  return (
    <section
      id="features-section"
      className="py-16 px-4 max-w-7xl mx-auto"
    >
      <motion.div
        className="text-center mb-16"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.span
          className="text-blue-600 dark:text-blue-400 font-semibold"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {label}
        </motion.span>
        <motion.h2
          className="text-4xl font-bold mt-2 mb-4 text-foreground"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          {title}
        </motion.h2>
        <motion.p
          className="text-xl text-muted-foreground max-w-3xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          {subtitle}
        </motion.p>
      </motion.div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            {...feature}
          />
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;
