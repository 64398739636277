import React from "react";
import { Link } from "react-router-dom";
import { ModeToggle } from "./ThemeToggle";
import { Button } from "./ui/button";
import AuthButton from "./common/AuthButton";
import Header from "./sections/Header";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div
      id="layout-container"
      className="flex flex-col min-h-screen bg-background text-foreground"
    >
      <Header />
      <main
        id="layout-main"
        className="flex-grow container mx-auto px-4 py-8"
      >
        {children}
      </main>
      <footer
        id="layout-footer"
        className="border-t mt-auto"
      >
        <div className="container mx-auto px-4 py-4 text-center">
          © 2024 Crashify AI. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Layout;
