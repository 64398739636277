import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCourses } from '../services/supabase';
import { logEvent } from '../services/analytics';
import { Card, CardHeader, CardTitle, CardContent, CardDescription, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Course } from '../types/supabase';
import { useAuth } from '../hooks/useAuth';

const DashboardPage: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        if (user) {
          const userCourses = await getCourses(user.id);
          setCourses(userCourses);
          logEvent('DASHBOARD_VIEWED', { courseCount: userCourses.length });
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
        logEvent('DASHBOARD_ERROR', { error: (error as Error).message });
        // TODO: Add error handling (e.g., display error message to user)
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [user]);

  const handleCreateCourse = () => {
    logEvent('CREATE_COURSE_CLICKED');
    navigate('/generate-course');
  };

  if (loading) {
    return <div id="dashboard-loading" className="text-center mt-8">Loading...</div>;
  }

  return (
    <div id="dashboard-container" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-bold mb-6">Your Courses</h1>
      {courses.length === 0 ? (
        <Card className="w-full max-w-md mx-auto">
          <CardHeader>
            <CardTitle className="text-center">Welcome to Crashify AI</CardTitle>
            <CardDescription className="text-center">Get started by creating your first course</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-center mb-4">
              Create personalized crash courses on any topic with the power of AI. Learn faster and smarter!
            </p>
          </CardContent>
          <CardFooter className="flex justify-center">
            <Button onClick={handleCreateCourse}>
              Create a Course
            </Button>
          </CardFooter>
        </Card>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {courses.map((course) => (
            <Link 
              key={course.id} 
              to={`/course/${course.id}`}
              onClick={() => logEvent('COURSE_SELECTED', { courseId: course.id, topic: course.topic })}
            >
              <Card>
                <CardHeader>
                  <CardTitle>{course.topic}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-sm text-muted-foreground">
                    Created on {new Date(course.created_at!).toLocaleDateString()}
                  </p>
                  {course.preferences && (
                    <p className="text-sm mt-2">
                      Preferences: {course.preferences}
                    </p>
                  )}
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default DashboardPage;