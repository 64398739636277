import * as amplitude from "@amplitude/analytics-browser";

// TODO: Replace with your actual Amplitude API key
const AMPLITUDE_API_KEY = "YOUR_AMPLITUDE_API_KEY";

// Initialize Amplitude
amplitude.init(AMPLITUDE_API_KEY);

/**
 * Log an event to Amplitude
 * @param eventName The name of the event
 * @param eventProperties Additional properties for the event
 */
export const logEvent = (eventName: string, eventProperties: Record<string, any> = {}) => {
  amplitude.logEvent(eventName, {
    ...eventProperties,
    app: "crashify",
  });
};

// Example usage:
// logEvent("COURSE_GENERATED", { topic: "Machine Learning" });