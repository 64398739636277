import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../services/supabase';

const ProcessingPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [status, setStatus] = useState('processing');

  useEffect(() => {
    const checkStatus = async () => {
      const { data, error } = await supabase
        .from('courses')
        .select('status')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error checking course status:', error);
        return;
      }

      if (data?.status === 'completed') {
        navigate(`/course/${id}`);
      }
    };

    const interval = setInterval(checkStatus, 5000); // Check every 5 seconds

    return () => clearInterval(interval);
  }, [id, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Generating Your Course</h1>
      <p>Please wait while we create your personalized course content. This can take a few minutes...</p>
      {/* You can add a loading spinner or progress bar here */}
    </div>
  );
};

export default ProcessingPage;