import React from "react";
import { motion } from "framer-motion";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";

interface SideHeroProps {
  title: string;
  subtitle: string;
  imageSrc: string;
  primaryCTA: {
    text: string;
    onClick: () => void;
  };
  secondaryCTA?: {
    text: string;
    link: string;
  };
  badge?: {
    text: string;
    link: string;
  };
  imagePosition?: "left" | "right";
}

const SideHero: React.FC<SideHeroProps> = ({
  title,
  subtitle,
  imageSrc,
  badge,
  primaryCTA,
  secondaryCTA,
  imagePosition = "right",
}) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div id="side-hero" className="py-12 lg:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          className={`grid grid-cols-1 lg:grid-cols-2 gap-12 items-center ${
            imagePosition === "left" ? "lg:grid-flow-col-dense" : ""
          }`}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {imagePosition === "left" && (
            <motion.div 
              className="flex justify-center items-center"
              variants={itemVariants}
            >
              <img
                src={imageSrc}
                alt="Hero illustration"
                className="object-cover rounded-lg shadow-xl"
              />
            </motion.div>
          )}
          <motion.div variants={itemVariants}>
            {badge && (
              <motion.div variants={itemVariants}>
                <Badge variant="secondary" className="mb-6">
                  <a
                    href={badge.link}
                    className="text-sm hover:text-blue-600 dark:hover:text-blue-400 transition-colors"
                  >
                    {badge.text}
                    <span className="ml-1">→</span>
                  </a>
                </Badge>
              </motion.div>
            )}
            <motion.h2 
              className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 text-foreground"
              variants={itemVariants}
            >
              {title}
            </motion.h2>
            <motion.p 
              className="text-xl text-muted-foreground mb-8"
              variants={itemVariants}
            >
              {subtitle}
            </motion.p>
            <motion.div 
              className="flex flex-col sm:flex-row items-center gap-4"
              variants={itemVariants}
            >
              <Button
                onClick={primaryCTA.onClick}
                size="lg"
                className="bg-blue-600 hover:bg-blue-700 text-white dark:bg-blue-500 dark:hover:bg-blue-600 shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:-translate-y-1"
              >
                {primaryCTA.text}
              </Button>
              {secondaryCTA && (
                <Button variant="outline" size="lg" asChild className="border-blue-200 dark:border-blue-800 hover:border-blue-300 dark:hover:border-blue-700">
                  <a href={secondaryCTA.link} className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300">
                    {secondaryCTA.text} →
                  </a>
                </Button>
              )}
            </motion.div>
          </motion.div>
          {imagePosition === "right" && (
            <motion.div 
              className="flex justify-center items-center"
              variants={itemVariants}
            >
              <img
                src={imageSrc}
                alt="Hero illustration"
                className="object-cover rounded-lg shadow-xl"
              />
            </motion.div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default SideHero;