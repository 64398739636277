import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '../components/ui/card';

const LoginPage: React.FC = () => {
  return (
    <div id="login-page" className="flex items-center justify-center min-h-screen bg-background">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle className="text-center">Welcome to Crashify AI</CardTitle>
          <CardDescription className="text-center">Sign in to create and manage your courses</CardDescription>
        </CardHeader>
        <CardContent>
          <p className="text-center mb-4">
            To get started, please sign in with your Google account. This allows you to create and manage your personalized crash courses.
          </p>
        </CardContent>
        <CardFooter className="flex justify-center">
          <Button variant="outline" asChild>
            <Link to="/">Back to Home</Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default LoginPage;