import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { supabase } from "../services/supabase";
import { logEvent } from "../services/analytics";
import { useAuth } from "../hooks/useAuth";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../components/ui/card";

const CourseGenerationPage: React.FC = () => {
  const [topic, setTopic] = useState("");
  const [preferences, setPreferences] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();

  const generateCourseOutline = async () => {
    const outlineResponse = await supabase.functions.invoke(
      "generate-course-outline",
      {
        body: JSON.stringify({ topic, preferences }),
      }
    );

    if (outlineResponse.error) throw outlineResponse.error;

    const { id } = outlineResponse.data.data;

    console.log("Course outline generated:", outlineResponse.data.data);
    console.log("ID:", id);

    logEvent("course_outline_generated", { topic });
    navigate(`/course/${id}/outline`);
  };

  const mutation = useMutation(generateCourseOutline, {
    onError: (error) => {
      console.error("Error generating course outline:", error);
      // TODO: Implement proper error handling and user feedback
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle>Generate Your Course Outline</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label htmlFor="topic" className="block text-sm font-medium mb-1">
                Topic
              </label>
              <Input
                id="topic"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                required
                placeholder="e.g., Introduction to Machine Learning"
              />
            </div>
            <div>
              <label
                htmlFor="preferences"
                className="block text-sm font-medium mb-1"
              >
                Preferences or Rules (optional)
              </label>
              <Textarea
                id="preferences"
                value={preferences}
                onChange={(e) => setPreferences(e.target.value)}
                placeholder="e.g., Focus on practical applications, include code examples"
                rows={4}
              />
            </div>
          </div>
          <CardFooter className="px-0 pt-4">
            <Button type="submit" className="w-full" disabled={mutation.isLoading}>
              {mutation.isLoading ? "Generating..." : "Generate Course Outline"}
            </Button>
          </CardFooter>
        </form>
      </CardContent>
    </Card>
  );
};

export default CourseGenerationPage;